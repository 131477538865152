import { createApp } from "vue";
import { router } from "@/router";
import App from "./App.vue";
import { BootstrapVue3, BToastPlugin } from "bootstrap-vue-3";
import Antd from "ant-design-vue";
import "ant-design-vue/dist/reset.css";
import store from "./store";
import vue3GoogleLogin from "vue3-google-login";
/********* components **********/
import Breadcrumb from "@/components/breadcrumb.vue";
import MyProductCard from "@/components/my-product-card.vue";
import NoResult from "@/components/no-result.vue";

/********* Layouts components **********/
import Header from "@/views/layouts/header.vue";
import Footer from "@/views/layouts/footer.vue";
import Auth_Footer from "@/views/layouts/auth-footer.vue";
import CreateProductButton from "@/views/layouts/create-product-button.vue";
import SettingsSidebar from "@/views/layouts/settings-sidebar.vue";

/********* Pages components **********/
import IndexBanner from "@/views/pages/index/index-banner.vue";
import IndexService from "@/views/pages/index/index-service.vue";
import IndexPopular from "@/views/pages/index/index-popular.vue";
import IndexCartypes from "@/views/pages/index/index-cartypes.vue";
import IndexRental from "@/views/pages/index/index-rental.vue";
import IndexChoose from "@/views/pages/index/index-choose.vue";
import HomeFaq from "@/views/pages/index/home-faq.vue";
import Listing_Sidebar from "@/views/pages/listing/listing-sidebar.vue";
import Listing_Head from "@/views/pages/listing/listing-details/listing-head.vue";
import Listing_CarPhoto from "@/views/pages/listing/listing-details/listing-carphoto.vue";
import Listing_Features from "@/views/pages/listing/listing-details/listing-features.vue";
import Listing_Description from "@/views/pages/listing/listing-details/listing-description.vue";
import Listingdetails_Sidebar from "@/views/pages/listing/listing-details/listingdetails-sidebar.vue";
import Listing from "@/views/pages/listing/listing-details/listing.vue";

/********* Modal components **********/
import CalendarBasicUpdateModal from "@/components/modal/calendar-basic-update.vue";
import GeneralPriceUpdateDrawer from "@/views/pages/user/product/update-price-drawer.vue";
import UpdateDayStatusModal from "@/components/modal/update-day-status-modal.vue";
import RCalendar from "@/components/r-calendar.vue";
import RCalendarItem from "@/components/r-calendar-item.vue";
import PriceBasicUpdateModal from "@/components/modal/price-basic-update.vue";
import ThingCard from "@/components/thing-card.vue";
import ChangePasswordModal from "@/components/modal/change-password-modal.vue";
import SpinnerModal from "@/components/modal/spinner-modal.vue";
import NotFound from "@/components/not-found.vue";
import FormInputNumber from "@/components/form-input-number.vue";
import GoogleLoginForm from "@/components/google-login-form.vue";
import GoogleLoginButton from "@/components/google-login-button.vue";
import ChatModal from "@/components/modal/chat-modal.vue";
import LostConectionModal from "@/components/modal/lost-connection-modal.vue";

// plugins
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "@fortawesome/fontawesome-free/css/fontawesome.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "@/assets/css/feather.css";
import "@/assets/css/style.css";
import { GOOGLE_CLIENT_ID } from "./constants";
import * as Sentry from "@sentry/vue";
import { SentryDNS, WebUrlRegex } from "./constants/sentry";
import VueGtag from "vue-gtag-next";
import { googleAnalyticID } from "./constants/google-analytics";

const app = createApp(App);

/********* components **********/
app.component("breadcrumb", Breadcrumb);
app.component("my-product-card", MyProductCard);
app.component("r-calendar", RCalendar);
app.component("r-calendar-item", RCalendarItem);
app.component("thing-card", ThingCard);
app.component("no-result", NoResult);

/********* Layouts components **********/
app.component("layout-header", Header);
app.component("layout-footer", Footer);
app.component("auth-footer", Auth_Footer);
app.component("settings-sidebar", SettingsSidebar);
app.component("not-found", NotFound);
app.component("create-product-button", CreateProductButton);

/********* Pages components **********/
app.component("index-banner", IndexBanner);
app.component("index-service", IndexService);
app.component("index-popular", IndexPopular);
app.component("index-cartypes", IndexCartypes);
app.component("index-rental", IndexRental);
app.component("index-choose", IndexChoose);
app.component("home-faq", HomeFaq);
app.component("listing-sidebar", Listing_Sidebar);
app.component("listing-head", Listing_Head);
app.component("listing-carphoto", Listing_CarPhoto);
app.component("listing-features", Listing_Features);
app.component("listing-description", Listing_Description);
app.component("listingdetails-sidebar", Listingdetails_Sidebar);
app.component("listing", Listing);
app.component("form-input-number", FormInputNumber);
app.component("google-login-form", GoogleLoginForm);
app.component("google-login-button", GoogleLoginButton);
app.component("chat-modal", ChatModal);
app.component("lost-connection-modal", LostConectionModal);

/********* Modal components **********/

app.component("calendar-basic-update-modal", CalendarBasicUpdateModal);
app.component("general-price-update-drawer", GeneralPriceUpdateDrawer);
app.component("update-day-status-modal", UpdateDayStatusModal);
app.component("price-basic-update-modal", PriceBasicUpdateModal);
app.component("change-password-modal", ChangePasswordModal);
app.component("spinner-modal", SpinnerModal);


Sentry.init({
  app,
  dsn: SentryDNS,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: [WebUrlRegex],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});


app.use(BootstrapVue3).use(BToastPlugin).use(Antd);
app.use(router);
app.use(store).use(vue3GoogleLogin, {
  clientId: GOOGLE_CLIENT_ID,
});

app.use(VueGtag, {
  property: {
    id: googleAnalyticID,
  },
});


app.mount("#app");

window.$store = store;
