import { initializeApp } from "firebase/app";
import {
  getFirestore,
  collection,
  getDocs,
  getDoc,
  doc,
  query,
  where,
  orderBy,
  startAfter,
  limit,
  writeBatch,
  runTransaction,
  setDoc,
  onSnapshot,
  or,
  and,
} from "firebase/firestore";
import { FIREBASE_CONFIG } from "@/constants";
const app = initializeApp(FIREBASE_CONFIG);
const db = getFirestore(app);
const subscribeListeners = [];

const firestoreService = {
  async setDoc(collectionName, { id, data }) {
    try {
      await setDoc(doc(db, collectionName, id), data);
    } catch (error) {
      console.error("Error setting doc: ", error);
    }
  },
  async commitBatch(operations) {
    const batch = writeBatch(db);
    operations.forEach((op) => {
      const docRef = doc(db, op.collectionName, op.id);
      if (op.type === "set") {
        batch.set(docRef, op.data);
      } else if (op.type === "update") {
        batch.update(docRef, op.data);
      } else if (op.type === "delete") {
        batch.delete(docRef);
      }
    });
    await batch.commit();
  },
  async updateWithTransaction(collectionName, { id, data }) {
    try {
      await runTransaction(db, async (transaction) => {
        const docRef = doc(db, collectionName, id);
        transaction.update(docRef, data);
      });
    } catch (error) {
      console.error("Error updating with transaction: ", error);
    }
  },
  async getDocument(collectionName, docId) {
    try {
      const docRef = doc(db, collectionName, docId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        return docSnap.data();
      } else {
        console.log("No such document!");
        return null;
      }
    } catch (error) {
      console.error("Error getting document: ", error);
    }
  },
  async getAllDocuments(collectionName, options = {}) {
    const {
      conditions = [],
      sortField = "",
      sortType = "",
      pageSize = 10,
      pageIndex = 0,
      after = "",
    } = options;
    // conditions = [
    //   { field: "status", operator: "==", value: "active" },
    //   { field: "age", operator: ">", value: 25 },
    // ];
    try {
      const colRef = collection(db, collectionName);
      let q = colRef;
      if (conditions.length > 0) {
        const queries = conditions.map((cond) =>
          where(cond.field, cond.operator, cond.value)
        );
        q = query(colRef, ...queries);
      }
      if (sortField && sortType) q = query(q, orderBy(sortField, sortType));
      if (pageIndex > 0) {
        const offsetQuery = query(q, limit(pageIndex * pageSize));
        const offsetSnapshot = await getDocs(offsetQuery);
        const lastVisible = offsetSnapshot.docs[offsetSnapshot.docs.length - 1];
        if (lastVisible) {
          q = query(q, startAfter(lastVisible));
        }
      }

      if (after) q = query(q, startAfter(new Date(after)));
      q = query(q, limit(pageSize));
      const colSnapshot = await getDocs(q);
      const docs = colSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      return docs;
    } catch (error) {
      console.error("Error getting documents: ", collectionName, error);
    }
  },

  listenToCollection(collectionName, callback, options = {}) {
    const {
      andConditions = [],
      sortField = "",
      sortType = "",
      orConditions = [],
    } = options;

    try {
      const colRef = collection(db, collectionName);
      let q = query(colRef);

      if (andConditions.length > 0) {
        q = query(
          q,
          and(
            ...andConditions.map((cond) =>
              where(cond.field, cond.operator, cond.value)
            )
          )
        );
      }

      if (orConditions.length > 0) {
        q = query(
          q,
          or(
            ...orConditions.map((cond) =>
              where(cond.field, cond.operator, cond.value)
            )
          )
        );
      }

      if (sortField && sortType) {
        q = query(q, orderBy(sortField, sortType));
      }

      const unsubscribe = onSnapshot(q, (snapshot) => {
        const docs = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        callback(docs);
      });

      subscribeListeners.push(unsubscribe);
      return unsubscribe;
    } catch (error) {
      console.error("Error listening to collection: ", error);
    }
  },
  detachAllListeners() {
    subscribeListeners.forEach((unsubscribe) => {
      unsubscribe();
    });
    subscribeListeners.length = 0;
  },
};
export default firestoreService;
