import chatService from "@/services/ChatService";
import firestoreService from "@/services/FirestoreService";
import realtimeService from "@/services/RealtimeService";
import { v4 as uuidv4 } from "uuid";
import { serverTimestamp } from "firebase/firestore";
import { increment } from "firebase/database";
import userService from "@/services/UserService";
import pick from "lodash.pick";
const state = {
  isOpenChatModal: undefined,
  chats: undefined,
  currentChat: undefined,
  isSuccess: undefined,
  isLoading: undefined,
  messages: undefined,
  isLoadingMessages: undefined,
  canLoadMoreMessages: true,
  offsetTime: undefined,
  lastMessageOfCurrent: undefined,
  isSettingSeen: false,
  newMessageCount: null,
  canLoadMoreChats: true,
};

const getters = {
  isOpenChatModal: (state) => state.isOpenChatModal,
  chats: (state) => {
    if (!state.chats) return undefined;
    return Object.keys(state.chats)
      .map((key) => {
        return { id: key, ...state.chats[key] };
      })
      .sort((a, b) => {
        return new Date(b.lastUpdated) - new Date(a.lastUpdated);
      });
  },
  currentChat: (state) => {
    if (!state.currentChat) return state.currentChat;
    return {
      ...state.currentChat,
      isNewChat: state.chats[state.currentChat.id] ? false : true,
    };
  },
  isSuccess: (state) => state.isSuccess,
  isLoading: (state) => state.isLoading,
  currentMessages: (state) => {
    return state.currentChat && state.messages
      ? (state.messages[state.currentChat.id] || [])
          .map((item) => ({
            ...item,
            createdAt: item.createdAt.seconds
              ? new Date(item.createdAt.seconds * 1000)
              : item.createdAt,
          }))
          .sort((a, b) => a.createdAt - b.createdAt)
      : [];
  },
  isLoadingMessages: (state) => state.isLoadingMessages,
  canLoadMoreMessages: (state) => state.canLoadMoreMessages,
  lastMessageOfCurrent: (state) => state.lastMessageOfCurrent,
  isSettingSeen: (state) => state.isSettingSeen,
  newMessageCount: (state) => state.newMessageCount,
  canLoadMoreChats: (state) => state.canLoadMoreChats,
};

const actions = {
  async getList({ commit, getters, state }, { isFirstLoad = true }) {
    commit("setLoading", true);
    const perPage = 10;
    const lastChat =
      !isFirstLoad && getters.chats.length
        ? getters.chats?.[getters.chats.length - 1]
        : null;
    const res = await chatService.getChats({
      perPage,
      ...(lastChat ? { after: lastChat.lastUpdated } : {}),
    });

    const listObject = (res.data || []).reduce((obj, item) => {
      obj[item.id] = item;
      return obj;
    }, {});

    commit("setChats", { ...state.chats, ...listObject });
    commit("setLoading", false);
    commit("setCanLoadMoreChats", res.data.length === perPage);
  },

  async createChat({ commit, dispatch }, { chatId, data }) {
    try {
      const [userFirst, userSecond] = chatId.split("-").map(Number);
      const messId = uuidv4();
      const current = serverTimestamp();
      const localTime = new Date();

      const batchOperations = [
        {
          type: "set",
          collectionName: "chats",
          id: chatId,
          data: {
            userFirst,
            userSecond,
            lastMessage: data.content,
            lastUpdated: current,
            createdAt: current,
            lastMessageId: messId,
          },
        },
        {
          type: "set",
          collectionName: `chats/${chatId}/messages`,
          id: messId,
          data: { ...data, createdAt: current },
        },
      ];

      await firestoreService.commitBatch(batchOperations);
      commit("setSuccess", true);

      commit("setMessages", {
        ...state.messages,
        [chatId]: [
          {
            id: messId,
            owner: data.owner,
            type: "text",
            createdAt: localTime,
            content: data.content,
          },
        ],
      });

      dispatch("updateNewMessageCount", { chatId });
    } catch (e) {
      console.log(e);
      commit("setSuccess", false);
    }
  },
  async getMessages(
    { commit, state, getters },
    { chatId, isFirstLoad = true }
  ) {
    try {
      if (isFirstLoad && state.messages && state.messages[chatId]) {
        commit("setIsLoadingMessage", false);
        return;
      }

      const pageSize = 20;
      commit("setIsLoadingMessage", true);
      const lastMessage =
        !isFirstLoad && getters.currentMessages.length
          ? getters.currentMessages?.[0]
          : null;

      const messages = await firestoreService.getAllDocuments(
        `chats/${chatId}/messages`,
        {
          sortField: "createdAt",
          sortType: "desc",
          pageSize,
          ...(lastMessage ? { after: lastMessage.createdAt } : {}),
        }
      );

      const currentList =
        state.messages && state.messages[chatId] ? state.messages[chatId] : [];
      const newList = [...currentList, ...messages];

      if (messages.length < pageSize) commit("setCanLoadMoreMessages", false);

      commit("setMessages", {
        ...state.messages,
        [chatId]: newList,
      });
      commit("setIsLoadingMessage", false);
    } catch (e) {
      commit("setIsLoadingMessage", false);
    }
  },
  async sendMessage({ commit, state, dispatch }, { chatId, data }) {
    try {
      const messId = uuidv4();
      const current = serverTimestamp();
      const localTime = new Date();

      const messages = { ...state.messages };
      const currentMessages = [...(messages[chatId] || [])];

      const newMess = {
        ...data,
        id: messId,
        createdAt: new Date(localTime - state.offsetTime),
      };
      currentMessages.push(newMess);
      messages[state.currentChat.id] = currentMessages;
      state.lastMessageOfCurrent = newMess;
      commit("setMessages", messages);
      commit("setSuccess", true);

      await firestoreService.setDoc(`chats/${chatId}/messages`, {
        id: messId,
        data: { ...data, createdAt: current },
      });

      await firestoreService.updateWithTransaction("chats", {
        id: chatId,
        data: {
          lastUpdated: current,
          lastMessage: data.content,
          lastMessageId: messId,
        },
      });

      dispatch("updateNewMessageCount", { chatId });
    } catch (e) {
      console.log(e);
      commit("setSuccess", false);
    }
  },

  async updateChats({ commit, state, rootState }, updatingChats) {
    const chatLists = { ...state.chats };

    for (const { id, ...data } of updatingChats) {
      if (data.lastUpdated) {
        const {
          userFirst,
          userSecond,
          lastUpdated,
          lastMessageId,
          lastMessage,
        } = data;

        const authId = rootState.auth.currentUser.id;
        const ownerId = userFirst === authId ? userSecond : userFirst;
        if (!chatLists[id]) {
          const user = await userService.getById(ownerId);
          chatLists[id] = {
            user: pick(user, ["fullName", "id", "avatar"]),
            lastMessageId: null,
          };
        }

        const seen =
          chatLists[id].lastMessageId === lastMessageId ? true : false;

        chatLists[id] = {
          ...chatLists[id],
          ...data,
          seen,
          lastUpdated: new Date(lastUpdated?.seconds * 1000),
        };

        if (!state.currentChat || id !== state.currentChat.id) {
          const messages = { ...state.messages };
          if (!messages[id]) {
            messages[id] = [];
          }
          if (
            messages[id].findIndex((item) => item.id === lastMessageId) === -1
          ) {
            messages[id] = [
              ...messages[id],
              {
                id: lastMessageId,
                owner: ownerId,
                type: "text",
                createdAt: new Date(lastUpdated?.seconds * 1000),
                content: lastMessage,
              },
            ];

            commit("setMessages", messages);
          }
        }
      }
    }

    commit("setChats", chatLists);
  },

  async addUpcomingMessages({ commit, state }, upcomingMessages) {
    const messages = { ...state.messages };
    const currentMessages = [...(messages[state.currentChat.id] || [])];

    for (const item of upcomingMessages) {
      if (
        currentMessages &&
        currentMessages.length &&
        currentMessages.findIndex(({ id }) => id === item.id) === -1
      ) {
        currentMessages.push(item);
        state.lastMessageOfCurrent = item;
      }
    }

    messages[state.currentChat.id] = currentMessages;
    commit("setMessages", messages);
  },

  async seen({ state, getters }, { chatId, messId }) {
    try {
      console.log("setting seen...");

      const lastMessageId = messId
        ? messId
        : getters.currentMessages.length
        ? getters.currentMessages[getters.currentMessages.length - 1].id
        : null;
      if (lastMessageId) {
        await chatService.seen({ chatId, messId: lastMessageId });

        state.chats[chatId].seen = true;
        if (state.currentChat && state.currentChat.id === chatId)
          state.currentChat.seen = true;
      }
    } catch (e) {}
  },

  async updateNewMessageCount({ rootState }, { chatId }) {
    try {
      const [userFirst, userSecond] = chatId.split("-").map(Number);
      const authId = rootState.auth.currentUser.id;

      await realtimeService.setDocument("new", {
        key: authId === userFirst ? userSecond : userFirst,
        value: increment(1),
      });
    } catch (e) {}
  },
};

const mutations = {
  setIsOpen(state, action) {
    state.isOpenChatModal = action;
  },
  setChats(state, action) {
    state.chats = action;
  },
  setCurrentChat(state, action) {
    if (action !== undefined && state.chats[action.id]) {
      state.currentChat = { ...state.chats[action.id], id: action.id };
    } else state.currentChat = action;
  },
  setSuccess(state, action) {
    state.isSuccess = action;
  },
  setLoading(state, action) {
    state.isLoading = action;
  },
  setMessages(state, action) {
    state.messages = action;
  },
  setIsLoadingMessage(state, action) {
    state.isLoadingMessages = action;
  },
  setCanLoadMoreMessages(state, action) {
    state.canLoadMoreMessages = action;
  },
  setOffsetTime(state, action) {
    state.offsetTime = action;
  },
  seenChat(state, chatId) {
    if (state.chats[chatId]) {
      state.chats[chatId].seen = true;
      if (state.currentChat && state.currentChat.id === chatId)
        state.currentChat.seen = true;
    }
  },
  setLastMessageOfCurrent(state, action) {
    state.lastMessageOfCurrent = action;
  },
  setIsSettingSeen(state, action) {
    state.isSettingSeen = action;
  },
  setChatActiveStatus(state, { chatId, status }) {
    if (state.chats[chatId]) {
      state.chats[chatId].activeStatus = status;
      if (state.currentChat && state.currentChat.id === chatId)
        state.currentChat.activeStatus = status;
    }
  },
  setNewMessageCount(state, count) {
    state.newMessageCount = count;
  },
  setCanLoadMoreChats(state, action) {
    state.canLoadMoreChats = action;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
