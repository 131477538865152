<template>
  <div class="content">
    <div class="container">
      <!-- Content Header -->
      <div class="content-header content-settings-header" v-if="props.isOwner">
        <h4>Cài đặt</h4>
      </div>
      <!-- /Content Header -->

      <div class="row">
        <!-- Settings Menu -->
        <settings-sidebar v-if="props.isOwner"></settings-sidebar>
        <!-- /Settings  Menu -->

        <!-- Settings Details -->
        <div :class="`${props.isOwner ? 'col-lg-9' : 'col-lg-12'}`">
          <div class="settings-info">
            <div
              class="settings-sub-heading d-flex justify-content-between align-items-cente"
            >
              <h4>Thông tin tài khoản</h4>
              <a
                v-if="!currentUser || (user && currentUser.id !== user.id)"
                @click="onClickChat"
                href="javascript:void(0);"
                class="btn-default btn-add-new"
                >Chat ngay</a
              >
            </div>
            <form action="javascript:;">
              <!-- Basic Info -->
              <div class="profile-info-grid">
                <div class="profile-info-header">
                  <h5>Thông tin cơ bản</h5>
                </div>

                <div class="profile-inner">
                  <a-form
                    :model="form"
                    ref="formRef"
                    :rules="formRules"
                    name="basic"
                    :label-col="{ span: 24 }"
                    :wrapper-col="{ span: 24 }"
                    autocomplete="off"
                  >
                    <div class="profile-info-pic p-0">
                      <div>
                        <avatar-upload
                          class="mt-3"
                          :fileList="form.avatar"
                          :maxCount="1"
                          :minHeight="200"
                          :maxHeight="1500"
                          :isLandscape="false"
                          :accept="'image/png, image/jpg'"
                          @fileListChanged="onImagesChange"
                          :canChange="props.isOwner"
                        />
                      </div>

                      <div class="profile-info-content">
                        <h6>Ảnh đại diện</h6>
                        <p v-if="props.isOwner">Định đạng png, jpeg, jpg.</p>
                      </div>
                    </div>

                    <p class="color-red" v-if="isErrorFormat">
                      Vui lòng chọn ảnh có định dạng image/png hoặc image/jpeg
                    </p>
                    <div class="row mt-2">
                      <div class="col-md-12">
                        <div class="profile-form-group">
                          <a-form-item label="Tên đầy đủ" name="fullName">
                            <a-input
                              v-model:value="form.fullName"
                              class="full-width form-control"
                              placeholder="Vui lòng nhập"
                              :disabled="!props.isOwner"
                            />
                          </a-form-item>
                        </div>
                      </div>
                      <div
                        class="input-block mt-3"
                        v-if="
                          props.isOwner &&
                          !(
                            currentUser.phone ||
                            currentUser.facebook ||
                            currentUser.zalo
                          )
                        "
                      >
                        <a-checkbox v-model:checked="isRenting"
                          >Bạn muốn cho thuê?</a-checkbox
                        >
                      </div>
                    </div>
                    <div
                      class="row"
                      v-if="
                        props.isOwner &&
                        (isRenting ||
                          currentUser.phone ||
                          currentUser.facebook ||
                          currentUser.zalo)
                      "
                    >
                      <div class="col-md-12 mb-3">
                        <hr class="mt-3 mb-4" />
                        <h6>Thông tin liên lạc:</h6>
                        <p :class="`${isContactErr ? 'color-red' : ''}`"> </p>
                      </div>
                      <div class="col-md-6">
                        <div class="profile-form-group">
                          <a-form-item label="Zalo" name="zalo">
                            <a-input
                              v-model:value="form.zalo"
                              class="full-width form-control"
                              placeholder="Vui lòng nhập"
                            />
                          </a-form-item>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="profile-form-group">
                          <a-form-item label="Facebook" name="facebook">
                            <a-input
                              v-model:value="form.facebook"
                              class="full-width form-control"
                              placeholder="Vui lòng nhập"
                            />
                          </a-form-item>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="profile-form-group">
                          <a-form-item label="Phone" name="phone">
                            <a-input
                              v-model:value="form.phone"
                              class="full-width form-control"
                              placeholder="Vui lòng nhập"
                            />
                          </a-form-item>
                        </div>
                      </div>
                    </div>
                  </a-form>
                </div>

                <div
                  class="profile-submit-btn mt-5 mb-3 mr-3"
                  v-if="props.isOwner"
                >
                  <button
                    type="submit"
                    class="btn btn-secondary"
                    @click="onCancle"
                  >
                    Hủy
                  </button>
                  <button
                    type="submit"
                    :disabled="isUpdating"
                    class="btn btn-primary"
                    @click="onSubmit"
                  >
                    Lưu thay đổi
                  </button>
                </div>
              </div>
              <!-- /Basic Info -->
              <!-- Profile Submit -->

              <div class="profile-info-grid mt-3 mb-3" v-if="props.isOwner">
                <div class="profile-info-header">
                  <h5>Đổi mật khẩu</h5>
                </div>
                <div class="profile-inner mb-3">
                  <a
                    href="javascript:void(0)"
                    class="btn btn-secondary"
                    @click="isChangingPassword = true"
                  >
                    Đổi
                  </a>
                </div>
              </div>
              <!-- <user-security-modal></user-security-modal> -->
              <change-password-modal
                :visible="isChangingPassword"
                @close="isChangingPassword = false"
              />
              <!-- /Profile Submit -->
            </form>
          </div>
        </div>
        <!-- /Settings Details -->
      </div>
    </div>
  </div>
  <spinner-modal :visible="isUpdating" />
</template>
<script setup>
import { ref, computed, watchEffect, reactive, watch } from "vue";
import { useStore } from "vuex";
import AvatarUpload from "@/components/single-image-upload.vue";
import { message } from "ant-design-vue";
import { useRouter } from "vue-router";
import { isValidFb, isValidPhone, checkEmptyString } from "@/utils/common";
import { trimStringFields } from "@/utils/common";

const store = useStore();
const router = useRouter();
const currentUser = computed(() => store.getters["auth/currentUser"]);
const user = computed(() => store.getters["user/getUser"]);
const isUpdating = computed(() => store.getters["user/getLoading"]);
const updateSucceeded = computed(() => store.getters["user/getSuccess"]);
const isContactErr = ref(false);

const isChangingPassword = ref(false);
const isRenting = ref(false);
const isErrorFormat = ref(false);

const props = defineProps({
  isOwner: { type: Boolean, required: true },
});

const id = router.currentRoute.value.params.id;

watch(updateSucceeded, (newValue, oldValue) => {
  if (newValue !== undefined) {
    if (newValue === true) {
      message.success("Cập nhật thành công");
    } else {
      message.error("Có lỗi vui lòng thử lại");
    }
    store.commit("user/succeed", undefined);
  }
});

const formRef = ref();
const form = reactive({
  avatar: [],
  fullName: "",
  zalo: "",
  facebook: "",
  phone: "",
});

const formRules = reactive({
  avatar: [
    {
      required: true,
      message: "Vui lòng chọn",
      trigger: "blur",
    },
  ],
  fullName: [
    {
      required: true,
      message: "Vui lòng nhập",
      trigger: "blur",
    },
    {
      max: 50,
      message: "Tên đầy đủ phải không quá 50 kí tự",
      trigger: "blur",
    },
    { validator: checkEmptyString, trigger: "blur" },
  ],
  zalo: [
    {
      validator: async (_rule, value) => {
        if (value && !isValidPhone(value)) {
          return Promise.reject(new Error("Số điện thoại zalo không hợp lệ"));
        }
      },
      trigger: "blur",
    },
  ],
  phone: [
    {
      validator: async (_rule, value) => {
        if (value && !isValidPhone(value)) {
          return Promise.reject(new Error("Số điện thoại không hợp lệ"));
        }
      },
      trigger: "blur",
    },
  ],
  facebook: [
    {
      validator: async (_rule, value) => {
        if (value && !isValidFb(value)) {
          return Promise.reject(new Error("Địa chỉ facebook không hợp lệ"));
        }
      },
      trigger: "blur",
    },
  ],
});

watchEffect(() => {
  if (user.value !== undefined && user.value !== null) {
    form.fullName = user.value.fullName;
    form.avatar = [
      {
        url: `${user.value.avatar.url}`,
      },
    ];
  }
});

watchEffect(() => {
  if (currentUser.value && !id) {
    form.fullName = currentUser.value.fullName;
    form.phone = currentUser.value.phone;
    form.zalo = currentUser.value.zalo;
    form.facebook = currentUser.value.facebook;
    if (currentUser.value?.avatar)
      form.avatar = [
        {
          url: `${currentUser.value.avatar.url}`,
        },
      ];
  }
});
const onCancle = () => {
  form.fullName = currentUser.value.fullName;
  form.phone = currentUser.value.phone;
  form.zalo = currentUser.value.zalo;
  form.facebook = currentUser.value.facebook;

  if (currentUser.value?.avatar)
    form.avatar = [
      {
        url: `${currentUser.value.avatar.url}`,
      },
    ];
};

const onSubmit = () => {
  isContactErr.value = false;
  formRef.value
    .validate()
    .then(() => {
      store.dispatch("user/updateInfoThenUpdateAvatar", {
        id: currentUser.value.id,
        info: trimStringFields(form),
        avatar: form.avatar[0].originFileObj
          ? form.avatar[0].originFileObj
          : null,
      });
    })
    .catch(() => {});
};

const onImagesChange = (newFileList) => {
  const { file, fileList } = newFileList;
  isErrorFormat.value = !["image/jpeg", "image/png"].includes(file.type);
  if (!isErrorFormat.value) form.avatar = fileList;
};

const onClickChat = () => {
  if (currentUser.value) {
    const chatId = `${Math.min(currentUser.value.id, user.value.id)}-${Math.max(
      currentUser.value.id,
      user.value.id
    )}`;
    store.commit("chat/setCurrentChat", { id: chatId, user: user.value });
    store.commit("chat/setIsOpen", true);
  } else {
    router.push("/dang-nhap");
  }
};
</script>
<style scoped>
.form-control:disabled {
  color: gray !important;
}
</style>
