<template>
  <div class="col-lg-4 theiaStickySidebar">
    <div class="stickysidebar">
      <div class="review-sec extra-service mt-0">
        <div class="review-header">
          <h4>
            {{
              currentItem.title
                .split(" ")
                .slice(
                  ProductTypeList.find(
                    (item) => item.value === currentItem.type
                  ).label.split(" ").length
                )
                .join(" ")
            }}
          </h4>
        </div>
        <ul class="booking-list info-list">
          <li v-for="item in data" :key="index">
            {{ item.title }}
            <span>{{ item.value }}</span>
          </li>
        </ul>
      </div>
      <div class="review-sec extra-service mt-0">
        <div class="review-header">
          <h4>Chủ sở hữu</h4>
        </div>
        <div class="owner-detail">
          <div class="owner-img">
            <router-link :to="`/nguoi-dung/${currentItem.owner.id}`"
              ><img
                class="avatar"
                :src="
                  currentItem.owner.avatar
                    ? currentItem.owner.avatar.url
                    : `https://ui-avatars.com/api/?name=${currentItem.owner.fullName}&size=300`
                "
                alt=""
                width="300"
                height="300"
            /></router-link>
          </div>
          <div class="reviewbox-list-rating">
            <h5>
              <router-link :to="`/nguoi-dung/${currentItem.owner.id}`">{{
                currentItem.owner.fullName
              }}</router-link>
            </h5>
          </div>
        </div>
        <ul class="booking-list info-list">
          <li>
            Tham gia
            <span>{{
              dayjs(currentItem.owner.createdAt).format("MM/YYYY")
            }}</span>
          </li>
          <li>
            Số lượng
            <span>{{ total }}</span>
          </li>
          <template v-if="showInfo">
            <li v-if="currentItem.owner.zalo">
              Zalo
              <span
                ><a
                  :href="`https://zalo.me/${currentItem.owner.zalo}`"
                  target="_blank"
                  >Mở ngay</a
                ></span
              >
            </li>
            <li v-if="currentItem.owner.facebook">
              Facebook
              <span
                ><a :href="currentItem.owner.facebook" target="_blank"
                  >Mở ngay</a
                ></span
              >
            </li>
            <li v-if="currentItem.owner.phone">
              Số điện thoại
              <span
                ><a :href="`tel:${currentItem.owner.phone}`">{{
                  currentItem.owner.phone
                }}</a></span
              >
            </li>
          </template>
          <li v-else>
            Liên hệ
            <div>
              <a-button class="btn-default" @click="showOrLogin"
                ><div>
                  {{ isAuthenticated ? "Hiển thị" : "Đăng nhập để xem" }}
                </div></a-button
              >
            </div>
          </li>
          <li>
            Chat
            <div>
              <a-button
                class="btn-default"
                :disabled="
                  isAuthenticated && currentUser.id === currentItem.owner.id
                "
                @click="chatOrLogin"
                ><div>
                  {{ isAuthenticated ? "Chat ngay" : "Đăng nhập để chat" }}
                </div></a-button
              >
            </div>
          </li>
        </ul>
      </div>
      <div class="review-sec extra-service mt-0">
        <div class="review-header">
          <h4>Giá thuê cơ bản</h4>
        </div>
        <ul class="booking-list info-list">
          <li>
            Giá
            <span>{{ formatCurrency(currentItem.price) }}K</span>
          </li>
          <li>
            Giao tận nơi
            <span
              ><i
                class="feather-check allow-type"
                v-if="currentItem.deliveryType !== 'autopick'"
              ></i
              ><i class="feather-x disallow-type" v-else></i
            ></span>
          </li>
          <li>
            Tự đến lấy
            <span
              ><i
                class="feather-check allow-type"
                v-if="currentItem.deliveryType !== 'ship'"
              ></i
              ><i class="feather-x disallow-type" v-else></i
            ></span>
          </li>
        </ul>
      </div>
      <div
        class="review-sec extra-service mt-0"
        v-if="currentItem.extraFees.length"
      >
        <div class="review-header">
          <h4>Phụ phí có thể phát sinh</h4>
        </div>
        <ul class="booking-list info-list">
          <li v-for="item in currentItem.extraFees" :key="index">
            <div class="extra-fee">
              {{ item.name }}
              <a-tooltip v-if="item.description">
                <template #title>{{ item.description }}</template>
                <i class="feather-info"></i>
              </a-tooltip>
            </div>
            <span>{{ formatCurrency(item.fee) }}K</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<style scoped>
.info-list {
  margin-bottom: 0;
}
.avatar {
  object-fit: cover;
}

.extra-fee {
  display: flex;
  align-items: center;
}

.extra-fee i {
  margin-left: 5px;
  color: #ff8b00;
  cursor: pointer;
}

.reviewbox-list-rating a {
  color: black;
}
</style>
<script setup>
import { computed, ref } from "vue";
import { useStore } from "vuex";
import dayjs from "dayjs";
import {
  memoryMapping,
  shoeMapping,
  shoeStatusMapping,
  formatCurrency,
} from "../../../../utils/common";
import { ProductTypeList } from "../../../../constants";
import { router } from "@/router";

const store = useStore();
const currentItem = computed(() => store.getters["thing/getCurrentItem"]);
const total = computed(() => store.getters["thing/total"]);
const isAuthenticated = computed(() => store.getters["auth/isAuthenticated"]);
const showInfo = ref(false);
const currentUser = computed(() => store.getters["auth/currentUser"]);

const showOrLogin = () => {
  if (isAuthenticated.value) {
    showInfo.value = true;
  } else {
    router.push("/dang-nhap");
  }
};

const chatOrLogin = () => {
  if (isAuthenticated.value) {
    const chatId = `${Math.min(
      currentUser.value.id,
      currentItem.value.owner.id
    )}-${Math.max(currentUser.value.id, currentItem.value.owner.id)}`;
    store.commit("chat/setCurrentChat", {
      id: chatId,
      user: currentItem.value.owner,
    });

    store.commit("chat/setIsOpen", true);
  } else {
    router.push("/dang-nhap");
  }
};

store.dispatch("thing/getList", {
  filters: {
    owner: {
      id: currentItem.value.owner.id,
    },
  },
  sort: { createdAt: "DESC" },
  perPage: 1,
  populate: [],
  select: [],
  requireAuth: false,
});

const data = computed(() => {
  switch (currentItem.value.type) {
    case "phone":
    case "tablet":
    case "pc":
      return [
        {
          title: "Thương hiệu",
          value:
            currentItem.value.brandOtherText || currentItem.value.brand.name,
        },
        {
          title: "Dòng máy",
          value: currentItem.value.device.model,
        },
        {
          title: "Năm sản xuất",
          value: currentItem.value.device.expYear,
        },
        {
          title: "Màu sắc",
          value: currentItem.value.device.color,
        },
        {
          title: "Bộ nhớ",
          value: memoryMapping[currentItem.value.device.memory],
        },
        {
          title: "Dung lượng máy",
          value: `${currentItem.value.device.capacity} GB`,
        },
        {
          title: "Dung lượng trống",
          value: `${currentItem.value.device.remainCapacity}%`,
        },
        ...(currentItem.value.device.serialNo
          ? [
              {
                title: "Seri",
                value: currentItem.value.device.serialNo,
              },
            ]
          : []),
      ];
    case "lightstick":
      return [
        {
          title: "Nghệ sĩ",
          value:
            currentItem.value.brandOtherText || currentItem.value.brand.name,
        },
        {
          title: "Tên",
          value: currentItem.value.lightstick.name,
        },
        {
          title: "Version",
          value: currentItem.value.lightstick.version,
        },
      ];
    case "shoe":
      return [
        {
          title: "Thương hiệu",
          value:
            currentItem.value.brandOtherText || currentItem.value.brand.name,
        },
        {
          title: "Loại",
          value: shoeMapping[currentItem.value.shoe.type],
        },
        {
          title: "Size giày",
          value: currentItem.value.shoe.size,
        },
        {
          title: "Độ cao đế",
          value: currentItem.value.shoe.height,
        },
        {
          title: "Tình trạng",
          value: shoeStatusMapping[currentItem.value.shoe.status],
        },
        {
          title: "Màu sắc",
          value: currentItem.value.shoe.color,
        },
      ];
    case "digitalcamera":
      return [
        {
          title: "Thương hiệu",
          value:
            currentItem.value.brandOtherText || currentItem.value.brand.name,
        },
        {
          title: "Dòng máy",
          value: currentItem.value.digitalcamera.model,
        },
        {
          title: "Năm sản xuất",
          value: currentItem.value.digitalcamera.expYear,
        },
        {
          title: "Màu sắc",
          value: currentItem.value.digitalcamera.color,
        },
        {
          title: "Dung lượng trống",
          value: `${currentItem.value.digitalcamera.remainCapacity}%`,
        },
        ...(currentItem.value.digitalcamera.serialNo
          ? [
              {
                title: "Seri",
                value: currentItem.value.digitalcamera.serialNo,
              },
            ]
          : []),
      ];
    case "membership":
      return [
        {
          title: "Nghệ sĩ",
          value:
            currentItem.value.brandOtherText || currentItem.value.brand.name,
        },
        {
          title: "Tên",
          value: currentItem.value.membership.name,
        },
        {
          title: "Năm",
          value: currentItem.value.membership.year,
        },
        {
          title: "Ngày hết hạn",
          value: dayjs(currentItem.value.membership.expiryDate).format(
            "DD/MM/YYYY"
          ),
        },
      ];
    case "speaker":
      return [
        {
          title: "Thương hiệu",
          value:
            currentItem.value.brandOtherText || currentItem.value.brand.name,
        },
        {
          title: "Dòng",
          value: currentItem.value.speaker.model,
        },
        {
          title: "Công suất",
          value: currentItem.value.speaker.wattage,
        },
        ...(currentItem.value.speaker.expYear
          ? [
              {
                title: "Năm sản xuất",
                value: currentItem.value.speaker.expYear,
              },
            ]
          : []),
      ];
    default:
      return [];
  }
});
</script>
