<template>
  <div id="app">
    <div class="main-wrapper">
      <router-view />
    </div>
  </div>
  <chat-modal
    v-if="isAuthenticated"
    :visible="isOpenChatModal && chats !== undefined"
    @close="store.commit('chat/setIsOpen', false)"
  ></chat-modal>

  <lost-connection-modal :visible="isLostConnection"></lost-connection-modal>
</template>

<script setup>
import { googleOneTap } from "vue3-google-login";
import { watch, computed, ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import realtimeService from "@/services/RealtimeService";
import { notification } from "ant-design-vue";
import { MessageOutlined } from "@ant-design/icons-vue";
import { h } from "vue";
import debounce from "lodash.debounce";

const store = useStore();
const router = useRouter();

const isSignupWithGoogle = computed(
  () => store.getters["auth/isSignupWithGoogle"]
);
const currentUser = computed(() => store.getters["auth/currentUser"]);
const isAuthenticated = computed(() => store.getters["auth/isAuthenticated"]);
const isOpenChatModal = computed(() => store.getters["chat/isOpenChatModal"]);
const chats = computed(() => store.getters["chat/chats"]);
const newMessageCount = computed(() => store.getters["chat/newMessageCount"]);
const firebaseAuthenticated = computed(
  () => store.getters["auth/firebaseAuthenticated"]
);
const showGoogleOneTab = ref(false);
const isLostConnection = ref(undefined);

const updateConnectionStatus = debounce((isConnected) => {
  if (isLostConnection.value === true && isConnected === true) {
    window.location.reload();
    isLostConnection.value = !isConnected;
  } else isLostConnection.value = !isConnected;
}, 1000);

watch(
  () => router.currentRoute.value,
  (newRoute) => {
    const { path } = newRoute;
    if (
      isAuthenticated.value === false &&
      !["/dang-nhap", "/dang-ky"].includes(path) &&
      !showGoogleOneTab.value
    ) {
      googleOneTap({ cancelOnTapOutside: false })
        .then((response) => {
          store.dispatch("auth/loginWithGoogle", {
            credential: response.credential,
          });
          showGoogleOneTab.value = true;
        })
        .catch((error) => {
          console.log(error);
          message.error("Lỗi hệ thống, vui lòng thử lại sau");
        });
    }
  }
);

watch(isSignupWithGoogle, (newVal) => {
  if (newVal !== undefined && newVal === true) {
    router.push("/dang-ky");
  }
});

watch(isAuthenticated, (val) => {
  if (val === true) {
    store.dispatch("auth/firebaseSignin");
    store.dispatch("chat/getList", { isFirstLoad: true });
  }
});

watch(firebaseAuthenticated, (val) => {
  if (val === true) {
    realtimeService.listenForUpdates(`new/${currentUser.value.id}`, (doc) => {
      store.commit("chat/setNewMessageCount", doc !== null ? doc : 0);
    });
  }
});

watch(newMessageCount, async (newCount, oldCount) => {
  if (!isOpenChatModal.value && oldCount === 0 && newCount > 0) {
    notification.open({
      message: "Bạn có tin nhắn mới",
      icon: () => h(MessageOutlined, { style: "color: #ffa632" }),
      placement: "bottomRight",
      style: {
        width: "280px",
      },
    });
  }

  if (newCount !== 0 && isOpenChatModal.value) {
    await realtimeService.setDocument("new", {
      key: currentUser.value.id,
      value: null,
    });
  }
});

watch(isOpenChatModal, async (val) => {
  if (val === true && newMessageCount.value !== 0) {
    await realtimeService.setDocument("new", {
      key: currentUser.value.id,
      value: null,
    });
  }
});

onMounted(() => {
  realtimeService.listenForUpdates(`.info/connected`, (isConnected) => {
    updateConnectionStatus(isConnected);
  });
});
</script>
