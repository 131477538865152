<template>
  <button
    v-if="currentUser"
    class="round-button"
    @click="onClick"
  >
    <plus-outlined />
  </button>
</template>

<script setup>
import { computed, createVNode } from "vue";
import { PlusOutlined, ExclamationCircleOutlined } from "@ant-design/icons-vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { Modal } from "ant-design-vue";

const store = useStore();
const router = useRouter();

const currentUser = computed(() => store.getters["auth/currentUser"]);
const onClick = () => {
  router.push("/quan-ly/them");
};
</script>

<style scoped>
.round-button {
  position: fixed;
  right: 20px;
  bottom: 20px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #ffa632;
  border: none;
  color: white;
  font-size: 24px;
  box-shadow: 0 0 15px rgba(255, 165, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}
</style>
