import axiosInstance from "./axiosConfig";
import FormData from "form-data";

const userService = {
  async update(id, data) {
    try {
      const response = await axiosInstance.put(`/users/${id}`, data);
      return response.data;
    } catch (error) {
      console.error("Error updating user:", error);
      throw error;
    }
  },
  async updateAvatar(file) {
    try {
      const formData = new FormData();
      formData.append("avatar", file);

      const response = await axiosInstance.put(
        `/users-permissions/avatar`,
        formData
      );
      return response.data;
    } catch (error) {
      console.error("Error updating avatr:", error);
      throw error;
    }
  },
  async getById(id, params) {
    try {
      const response = await axiosInstance.get(`/users/${id}`, {
        params,
      });
      if (response.data && !response.data.avatar) {
        response.data.avatar = {
          url: `https://ui-avatars.com/api/?name=${response.data.fullName}`,
        };
      }
      return response.data;
    } catch (error) {
      console.error("Error updating user:", error);
      return null;
    }
  },
};

export default userService;
