import axiosInstance from "./axiosConfig";

const chatService = {
  async getChats(params) {
    return axiosInstance
      .get("/chats", { params })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw new Error("Fetching chats error: ", error);
      });
  },
  async seen({ chatId, messId }) {
    return axiosInstance
      .post(`/chats/${chatId}/messages/${messId}/seen`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw new Error("Fetching seen chat error: ", error);
      });
  },
};

export default chatService;
