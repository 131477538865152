<template>
  <!-- Header -->
  <header class="header">
    <div class="container-fluid">
      <nav class="navbar navbar-expand-lg header-nav">
        <div class="navbar-header">
          <a
            @click="onClickChat"
            v-if="isAuthenticated"
            href="javascript:void(0);"
            class="mobile-chat-icon"
          >
            <img src="@/assets/img/chat.png" width="30" alt="Icon" />
            <span
              v-if="newMessageCount && newMessageCount > 0"
              class="new-message-dot"
            ></span>
          </a>
          <router-link to="/" class="navbar-brand logo">
            <img src="@/assets/img/logo.svg" class="img-fluid" alt="Logo" />
          </router-link>
          <router-link to="/" class="navbar-brand logo-small">
            <img
              src="@/assets/img/logo-small.svg"
              class="img-fluid"
              alt="Logo"
            />
          </router-link>
        </div>
        <div class="main-menu-wrapper">
          <div class="menu-header">
            <router-link to="/" class="menu-logo">
              <img src="@/assets/img/logo.svg" class="img-fluid" alt="Logo" />
            </router-link>
          </div>
        </div>
        <ul class="nav header-navbar-rht" v-if="!isAuthenticated">
          <a
            id="mobile_btn"
            href="javascript:void(0);"
            class="dropdown-toggle nav-link"
            data-bs-toggle="dropdown"
          >
            <span class="bar-icon">
              <span></span>
              <span></span>
              <span></span>
            </span>
          </a>
          <div class="dropdown-menu dropdown-menu-end">
            <router-link
              class="nav-link header-login dropdown-item"
              to="/dang-nhap"
              ><span><i class="fa-regular fa-user"></i></span>Đăng
              nhập</router-link
            >
            <router-link class="nav-link header-reg dropdown-item" to="/dang-ky"
              ><span><i class="fa-solid fa-lock"></i></span>Đăng ký</router-link
            >
          </div>

          <li class="btn nav-item">
            <router-link class="nav-link header-login" to="/dang-nhap"
              ><span><i class="fa-regular fa-user"></i></span>Đăng
              nhập</router-link
            >
          </li>
          <li class="btn nav-item">
            <router-link class="nav-link header-reg" to="/dang-ky"
              ><span><i class="fa-solid fa-lock"></i></span>Đăng ký</router-link
            >
          </li>
        </ul>

        <ul class="nav header-navbar-rht" v-if="isAuthenticated && currentUser">
          <!-- User Menu -->
          <li class="nav-item dropdown has-arrow logged-item">
            <a
              href="javascript:void(0);"
              class="desktop-chat-icon"
              @click="onClickChat"
              v-if="firebaseAuthenticated && chats !== undefined"
            >
              <img
                src="@/assets/img/chat.png"
                width="26"
                alt="Icon"
              />
              <span
                v-if="newMessageCount && newMessageCount > 0"
                class="new-message-dot"
              ></span>
            </a>
            <a
              href="javascript:void(0);"
              class="dropdown-toggle nav-link"
              data-bs-toggle="dropdown"
            >
              <span class="user-img">
                <img
                  class="rounded-circle"
                  :src="`${currentUser?.avatar?.url}`"
                  alt="Profile"
                />
              </span>
              <span class="user-text">{{ currentUser?.fullName }}</span>
            </a>
            <div class="dropdown-menu dropdown-menu-end">
              <router-link class="dropdown-item" to="/quan-ly/san-pham">
                <i class="feather-user-check"></i>Trang của tôi
              </router-link>
              <a
                class="dropdown-item"
                href="javascript:void(0);"
                @click="logOut"
              >
                <i class="feather-power"></i> Đăng xuất
              </a>
            </div>
          </li>
          <!-- /User Menu -->
        </ul>
      </nav>
    </div>
  </header>

  <!-- /Header -->
</template>

<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
import { router } from "@/router";
const store = useStore();

const currentUser = computed(() => store.getters["auth/currentUser"]);
const isAuthenticated = computed(() => store.getters["auth/isAuthenticated"]);
const firebaseAuthenticated = computed(
  () => store.getters["auth/firebaseAuthenticated"]
);
const chats = computed(() => store.getters["chat/chats"]);
const newMessageCount = computed(() => store.getters["chat/newMessageCount"]);

const logOut = () => {
  store.dispatch("auth/logout");
  router.push("/");
};

const onClickChat = () => {
  store.commit("chat/setIsOpen", true);
};
</script>
