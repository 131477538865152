<template>
  <div class="content dashboard-content">
    <div class="container">
      <div
        class="content-header d-flex justify-content-between align-items-center"
      >
        <h4> {{ props.isOwner ? "Sản phẩm của tôi" : "Sản phẩm" }}</h4>
        <a
          v-if="props.isOwner"
          @click="onAddNew"
          href="javascript:void(0);"
          class="btn-default btn-add-new"
          >Thêm sản phẩm</a
        >
      </div>
      <div :class="isLoading ? 'spinner-container' : ''">
        <a-spin :spinning="isLoading">
          <a-tabs
            v-model:activeKey="activeKey"
            type="card"
            v-if="props.isOwner"
          >
            <a-tab-pane
              key="approve"
              :tab="`Đang hoạt động (${count('approve')})`"
            >
              <no-result
                v-if="count('approve') === 0 && !isLoading"
                class="p-5"
                text="Bạn chưa có sản phẩm nào. Thêm ngay sản phẩm để cho thuê
                nhé!"
              ></no-result>
              <div class="row">
                <!-- col -->
                <div
                  class="col-lg-4 col-md-6 col-12"
                  v-for="item in items"
                  :key="item.id"
                  data-aos="fade-down"
                >
                  <my-product-card
                    :item="item"
                    :isOwner="true"
                  />
                </div>
                <!-- /col -->
              </div>
            </a-tab-pane>
            <a-tab-pane
              key="waiting"
              :tab="`Đang chờ duyệt (${count('waiting')})`"
            >
              <div class="row">
                <no-result
                  v-if="count('waiting') === 0 && !isLoading"
                  class="p-5"
                  text="Bạn chưa có sản phẩm nào. Thêm ngay sản phẩm để cho thuê
                nhé!"
                ></no-result>
                <!-- col -->
                <div
                  class="col-lg-4 col-md-6 col-12"
                  v-for="item in items"
                  :key="item.id"
                  data-aos="fade-down"
                >
                  <my-product-card
                    :item="item"
                    :isOwner="true"
                  />
                </div>
                <!-- /col -->
              </div></a-tab-pane
            >
            <a-tab-pane
              key="block"
              :tab="`Vi phạm (${count('block')})`"
            >
              <div class="row">
                <no-result
                  v-if="count('block') === 0 && !isLoading"
                  class="p-5"
                  text="Bạn chưa có sản phẩm nào. Thêm ngay sản phẩm để cho thuê
                nhé!"
                ></no-result>
                <!-- col -->
                <div
                  class="col-lg-4 col-md-6 col-12"
                  v-for="item in items"
                  :key="item.id"
                  data-aos="fade-down"
                >
                  <my-product-card
                    :item="item"
                    :isOwner="true"
                  />
                </div>
                <!-- /col -->
              </div></a-tab-pane
            >
          </a-tabs>
          <div
            class="row"
            v-if="!props.isOwner"
          >
            <!-- col -->
            <no-result
              v-if="items.length === 0 && !isLoading"
              class="p-5"
              text="Người dùng chưa có sản phẩm nào"
            ></no-result>
            <div
              class="col-lg-4 col-md-6 col-12"
              v-for="item in items"
              :key="item.id"
              data-aos="fade-down"
            >
              <thing-card
                :item="item"
                screen="recommend"
              ></thing-card>
            </div>
            <!-- /col -->
          </div>
        </a-spin>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watchEffect, watch, createVNode } from "vue";
import { useStore } from "vuex";
import { router } from "@/router";
import { ExclamationCircleOutlined } from "@ant-design/icons-vue";
import { Modal } from "ant-design-vue";

const store = useStore();
const props = defineProps({
  isOwner: { type: Boolean, required: true },
  ownerId: { type: Number, required: true },
});

const emits = defineEmits(["navigateToProfile"]);

const activeKey = ref("approve");
const allProducts = computed(() => store.getters["thing/list"]);
const isLoading = computed(() => store.getters["thing/isListLoading"]);
const items = ref([]);

const currentUser = computed(() => store.getters["auth/currentUser"]);

watchEffect(() => {
  store.dispatch("thing/getList", {
    filters: {
      owner: {
        id: props.ownerId,
      },
    },
    sort: { createdAt: "DESC" },
    perPage: 100,
    populate: props.isOwner
      ? ["images", "locations"]
      : ["images", "locations", "owner", "owner.avatar"],
    select: props.isOwner
      ? [
          "id",
          "type",
          "title",
          "price",
          "publishedAt",
          "status",
          "deliveryType",
        ]
      : ["title", "price", "deliveryType"],
  });
});

watch(allProducts, (newVal, oldVal) => {
  items.value = props.isOwner
    ? newVal?.filter((item) => (item.status || "waiting") === activeKey.value)
    : newVal;
});

watch(activeKey, (newVal, oldVal) => {
  items.value = allProducts.value?.filter(
    (item) => (item.status || "waiting") === newVal
  );
});

const count = (key) => {
  return allProducts.value?.filter((item) => (item.status || "waiting") === key)
    .length;
};

const onAddNew = () => {
  router.push("/quan-ly/them");
};
</script>
<style scoped>
.spinner-container {
  min-height: 20em;
  padding-top: 5em;
}
.btn-add-new {
  height: 40px;
  width: 150px;
  text-align: center;
  line-height: 29px;
}
</style>
