import authService from "@/services/AuthService";
import realtimeService from "@/services/RealtimeService";
import { increment } from "firebase/database";
import firestoreService from "@/services/FirestoreService";
const state = {
  currentUser: null,
  isAuthenticated: undefined,
  token: JSON.parse(localStorage.getItem("userInfo"))?.token || null,
  isSuccess: undefined,
  isSignupWithGoogle: undefined,
  googleLoginData: undefined,
  firebaseAuthenticated: undefined,
};

const getters = {
  currentUser: (state) => state.currentUser,
  isAuthenticated: (state) => state.isAuthenticated,
  token: (state) => state.token,
  getSuccess: (state) => state.isSuccess,
  isSignupWithGoogle: (state) => state.isSignupWithGoogle,
  googleLoginData: (state) => state.googleLoginData,
  firebaseAuthenticated: (state) => state.firebaseAuthenticated,
};

const actions = {
  async login({ commit, dispatch }, { identifier, password }) {
    try {
      const res = await authService.login(identifier, password);
      if (res.user && res.jwt) {
        commit("setToken", res.jwt);
        commit("setIsAuthenticated", true);
        dispatch("getMe");
        commit("succeed", true);
      }
    } catch (e) {
      commit("succeed", false);
    }
  },
  async logout({ commit, state }) {
    await realtimeService.setDocument("connections", {
      key: state.currentUser.id,
      value: 0,
    });

    commit("setCurrentUser", null);
    commit("setIsAuthenticated", false);
    commit("setFirebaseAuthenticated", false);
    commit("setToken", null);
    commit("succeed", undefined);
    commit("chat/setChats", undefined, { root: true });
    commit("chat/setNewMessageCount", null);

    firestoreService.detachAllListeners();
    realtimeService.detachAllListeners();
    realtimeService.cancelOnDisconnectRefs();
  },
  async getMe({ commit }) {
    const { serverTime, ...user } = await authService.getMe();

    if (serverTime) {
      const offsetMilliseconds = new Date() - new Date(serverTime);
      commit("chat/setOffsetTime", offsetMilliseconds, { root: true });
    }

    commit("setCurrentUser", user);
    if (user) commit("setIsAuthenticated", true);
    else {
      commit("setIsAuthenticated", false);
      commit("setToken", null);
    }
  },
  async checkEmailExisted({ commit }, email) {
    return await authService.checkEmailExisted(email);
  },
  async sendOTP({ commit }, email) {
    await authService.sendOTP(email);
  },
  async register({ commit }, userData) {
    try {
      const { jwt, user } = await authService.register(userData);
      commit("setCurrentUser", user);
      commit("setIsAuthenticated", true);
      commit("setToken", jwt);
      commit("succeed", true);
    } catch (e) {
      commit("succeed", false);
    }
  },
  async forgotPassword({ commit }, email) {
    try {
      await authService.forgotPassword(email);
      commit("succeed", true);
    } catch (e) {
      commit("succeed", false);
    }
  },
  async changePassword({ commit }, { oldPassword, newPassword }) {
    try {
      await authService.changePassword({ oldPassword, newPassword });
      commit("succeed", true);
    } catch (e) {
      commit("succeed", false);
    }
  },

  async loginWithGoogle({ commit, dispatch }, data) {
    try {
      const res = await authService.loginWithGoogle(data);
      if (res.user && res.jwt) {
        commit("setToken", res.jwt);
        commit("setIsAuthenticated", true);
        dispatch("getMe");
        commit("succeed", true);
      } else if (res && res.isNewUser) {
        commit("setIsSignupWithGoogle", true);
        commit("setGoogleLoginData", data);
      }
    } catch (e) {
      commit("succeed", false);
      commit("setIsSignupWithGoogle", false);
    }
  },
  async firebaseSignin({ commit, state }) {
    try {
      const res = await authService.getFirebaseToken();
      if (res && res.token) {
        const user = await authService.firebaseSignIn(res.token);
        if (user) {
          commit("setFirebaseAuthenticated", true);
          await realtimeService.setDocument("connections", {
            key: state.currentUser.id,
            value: increment(1),
            onDisconnectValue: increment(-1),
          });
        }
      }
    } catch (e) {
      commit("setFirebaseAuthenticated", false);
    }
  },
};

const mutations = {
  setCurrentUser(state, user) {
    state.currentUser = user;
  },
  setIsAuthenticated(state, isAuthenticated) {
    state.isAuthenticated = isAuthenticated;
  },
  setToken(state, token) {
    state.token = token;
  },
  succeed(state, action) {
    state.isSuccess = action;
  },
  setIsSignupWithGoogle(state, action) {
    state.isSignupWithGoogle = action;
  },
  setGoogleLoginData(state, action) {
    state.googleLoginData = action;
  },
  setFirebaseAuthenticated(state, action) {
    state.firebaseAuthenticated = action;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
