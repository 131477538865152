import dayjs from "dayjs";
import { LISTING_PARAM_TYPE } from "@/constants";

export const isCalendarDisabledDate = (day) => {
  const twoYearsFromNow = new Date().getFullYear() + 2;
  const endOfTwoYearsFromNow = new Date(twoYearsFromNow, 11, 31);
  return (
    isLessThanToday(day) || day > endOfTwoYearsFromNow.setHours(0, 0, 0, 0)
  );
};

export const isLessThanToday = (day) => {
  const now = new Date();
  now.setHours(0, 0, 0, 0);
  return day < now;
};

export const getMonthNow = () => {
  const now = new Date();
  now.setHours(0, 0, 0, 0);
  now.setDate(1);
  return now;
};

export const isSameMonth = (day1, day2) => {
  return (
    day1.getMonth() === day2.getMonth() &&
    day1.getFullYear() === day2.getFullYear()
  );
};

export const getDate = (day, month, year) => {
  const date = new Date();
  date.setMonth(+month);
  date.setFullYear(+year);
  date.setDate(+day);
  date.setHours(0, 0, 0, 0);
  return date;
};

export const formatDate = (date, format = "YYYY-MM-DD") => {
  return dayjs(date).format(format);
};

export const dayOfWeekSupported = [
  "sunSupported",
  "monSupported",
  "tueSupported",
  "wedSupported",
  "thuSupported",
  "friSupported",
  "satSupported",
];

export const validateUrl = (url) => {
  const pattern = /^(.*)-([A-Za-z0-9]+)$/;
  const match = url.match(pattern);

  if (!match) {
    return undefined;
  }

  try {
    const num = parseInt(atob(match[2]));
    if (isNaN(num) || num <= 0) {
      return undefined;
    }
    return num;
  } catch (error) {
    return undefined;
  }
};

export const memoryMapping = {
  gb1: "1 GB",
  gb2: "2 GB",
  gb4: "4 GB",
  gb6: "6 GB",
  gb8: "8 GB",
  gb16: "16 GB",
};

export const shoeMapping = {
  sneaker: "Sneakers (Giày thể thao)",
  boot: "Boots (Giày ống)",
  loafer: "Loafers (Giày lười)",
  flipflop: "Flip-flops (Dép quai hậu)",
  espadrilles: "Espadrilles (Dép lanh)",
  oxford: "Oxfords (Giày oxford)",
  wedges: "Wedges (Giày cao gót gò ép)",
  athletic: "Athletic shoes (Giày thể thao)",
  slippers: "Slippers (Dép nhà)",
  pump: "Pumps (Giày đế cao gót)",
  clog: "Clogs (Dép gác cổ)",
  moccasin: "Moccasins (Giày lười mô cưa)",
};

export const shoeStatusMapping = {
  new: "Mới",
  old: "Đã sử dụng",
};

export const shuffle = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

export const isValidFb = (url) => {
  if (url)
    return /^(?:https?:\/\/)?(?:www\.)?(?:facebook\.com|fb\.com)\/(.*?)$/i.test(
      url
    );
  return true;
};

export const isValidPhone = (phone) => {
  if (phone) {
    if (!phone.startsWith("0")) {
      return isValidPhone(`0${phone}`);
    }
    if (
      ![
        "086",
        "096",
        "087",
        "098",
        "039",
        "038",
        "037",
        "036",
        "035",
        "034",
        "033",
        "032",
        "091",
        "094",
        "088",
        "083",
        "084",
        "085",
        "081",
        "082",
        "070",
        "079",
        "077",
        "076",
        "078",
        "089",
        "090",
        "093",
        "092",
        "052",
        "056",
        "058",
        "099",
        "059",
        "087",
      ].some((item) => phone.startsWith(item))
    ) {
      return false;
    }
    return /^\d{10}$/i.test(phone);
  }
  return true;
};

export const isValidEmail = (email) => {
  if (email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }
};

export const generateUrl = (id, title) => {
  const stringToBase64 = (str) => {
    let base64 = btoa(str);
    while (base64.charAt(base64.length - 1) === "=") {
      base64 = base64.slice(0, -1);
    }
    return base64;
  };
  const alphanumeric = (input) => {
    if (input.match(/^[0-9a-zA-Z]+$/)) {
      return true;
    }

    return input in TranslateVNUS;
  };
  const TranslateVNUS = {
    é: "e",
    è: "e",
    ẻ: "e",
    ẽ: "e",
    ẹ: "e",
    ê: "e",
    ế: "e",
    ề: "e",
    ể: "e",
    ễ: "e",
    ệ: "e",
    ý: "y",
    ỳ: "y",
    ỷ: "y",
    ỹ: "y",
    ỵ: "y",
    ú: "u",
    ù: "u",
    ủ: "u",
    ũ: "u",
    ụ: "u",
    ư: "u",
    ứ: "u",
    ừ: "u",
    ử: "u",
    ữ: "u",
    ự: "u",
    í: "i",
    ì: "i",
    ỉ: "i",
    ĩ: "i",
    ị: "i",
    ó: "o",
    ò: "o",
    ỏ: "o",
    õ: "o",
    ọ: "o",
    ô: "o",
    ố: "o",
    ồ: "o",
    ổ: "o",
    ỗ: "o",
    ộ: "o",
    ơ: "o",
    ớ: "o",
    ờ: "o",
    ở: "o",
    ỡ: "o",
    ợ: "o",
    á: "a",
    à: "a",
    ả: "a",
    ã: "a",
    ạ: "a",
    â: "a",
    ấ: "a",
    ầ: "a",
    ẩ: "a",
    ẫ: "a",
    ậ: "a",
    ă: "a",
    ắ: "a",
    ằ: "a",
    ẳ: "a",
    ẵ: "a",
    ặ: "a",
    đ: "d",
  };
  let result = "";
  for (let i = 0; i < title.length; i++) {
    const item = title.charAt(i).toLowerCase();
    if (alphanumeric(item)) {
      if (item in TranslateVNUS) {
        result += TranslateVNUS[item];
      } else {
        result += item;
      }
    } else {
      result += "-";
    }
  }

  result = result.replace(/(-)+/g, "-");
  if (result.charAt(result.length - 1) === "-") {
    result = result.substring(0, result.length - 1);
  }

  if (result.charAt(0) === "-") {
    result = result.substring(1);
  }

  for (const item of Object.keys(LISTING_PARAM_TYPE)) {
    if (result.startsWith(item)) {
      const index = item.split("-").length;
      const parts = result.split("-");
      return `/${parts.slice(0, index).join("-")}/${parts
        .slice(index)
        .join("-")}-${stringToBase64(id.toString())}`;
    }
  }

  return `/${result}`;
};

export const trimStringFields = (obj, exceptFields = []) => {
  if (!Array.isArray(exceptFields)) exceptFields = [];
  if (typeof obj === "string") return obj.trim();
  if (typeof obj !== "object" || obj === null) return obj;

  if (Array.isArray(obj)) {
    return obj.map(trimStringFields);
  }

  return Object.keys(obj).reduce((acc, key) => {
    const value = obj[key];
    if (typeof value === "string" && !(exceptFields || []).includes(key)) {
      acc[key] = value.trim();
    } else if (typeof value === "object") {
      acc[key] = trimStringFields(value);
    } else {
      acc[key] = value;
    }
    return acc;
  }, {});
};

export const checkEmptyString = async (_rule, value) => {
  return value && value.trim() === ""
    ? Promise.reject(new Error("Vui lòng nhập"))
    : Promise.resolve();
};

export const formatCurrency = (number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const numberRules = ({ isRequired, min, max, trigger = "blur" }) => [
  ...(min !== undefined
    ? [
        {
          validator: async (_rule, value) => {
            if (value && value <= min) {
              return Promise.reject(`Vui lòng nhập số lớn hơn ${min}`);
            } else {
              return Promise.resolve();
            }
          },
          trigger,
        },
      ]
    : []),
  ...(max !== undefined
    ? [
        {
          validator: async (_rule, value) => {
            if (value && value > max) {
              return Promise.reject(
                `Vui lòng nhập số nhỏ hơn hoặc bằng ${max}`
              );
            } else {
              return Promise.resolve();
            }
          },
          trigger,
        },
      ]
    : []),
  ...(isRequired
    ? [
        {
          required: true,
          message: "Vui lòng nhập",
          trigger,
        },
      ]
    : []),
];

export const isSameDay = (date1, date2) => {
  const d1 = new Date(date1);
  const d2 = new Date(date2);
  return (
    d1.getFullYear() === d2.getFullYear() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getDate() === d2.getDate()
  );
};

export const isYesterday = (date) => {
  const d = new Date(date);
  const now = new Date();

  const yesterday = new Date(now);
  yesterday.setDate(now.getDate() - 1);

  return (
    d.getFullYear() === yesterday.getFullYear() &&
    d.getMonth() === yesterday.getMonth() &&
    d.getDate() === yesterday.getDate()
  );
};

export const isToday = (date) => {
  const d = new Date(date);
  const now = new Date();

  return (
    d.getFullYear() === now.getFullYear() &&
    d.getMonth() === now.getMonth() &&
    d.getDate() === now.getDate()
  );
};
