<template>
  <a-modal
    title=""
    v-model:open="props.visible"
    :footer="null"
    :closable="false"
    width="100%"
    wrap-class-name="full-modal"
  >
    <div class="error-page">
      <div class="error-box">
        <img
          src="@/assets/img/lost-connection.svg"
          class="img-fluid"
          alt="Lost connections"
        />
        <h3>Mất kết nối Internet</h3>
        <p>Vui lòng kiểm tra kết nối Internet của bạn</p>
        <div class="back-button">
          <button class="btn btn-primary" @click="refreshPage"
            >Tải lại trang</button
          >
        </div>
      </div>
    </div>
  </a-modal>
</template>

<script setup>
const props = defineProps({
  visible: { type: Boolean, required: true },
});

const refreshPage = () => {
  window.location.reload();
};
</script>
