import { initializeApp } from "firebase/app";
import {
  getDatabase,
  ref,
  set,
  remove,
  get,
  onValue,
  off,
  onDisconnect,
} from "firebase/database";
import { FIREBASE_CONFIG } from "@/constants";
const app = initializeApp(FIREBASE_CONFIG);
const db = getDatabase(app);

const subscribeListeners = [];
const onDisconnectRefs = [];

const realtimeService = {
  async setDocument(collectionName, { key, value, onDisconnectValue = null }) {
    try {
      const itemRef = ref(db, `${collectionName}/${key}`);
      await set(itemRef, value);
      if (onDisconnectValue) {
        onDisconnect(itemRef).set(onDisconnectValue);
        onDisconnectRefs.push(onDisconnect(itemRef));
      }
    } catch (error) {
      console.error("Error setting document: ", error);
    }
  },
  async deleteDocument(collectionName, docId) {
    try {
      const docRef = ref(db, `${collectionName}/${docId}`);
      await remove(docRef);
    } catch (error) {
      console.error("Error deleting document: ", error);
    }
  },

  async getDocument(collectionName, docId) {
    try {
      const docRef = ref(db, `${collectionName}/${docId}`);
      const docSnap = await get(docRef);
      if (docSnap.exists()) {
        return docSnap.val();
      } else {
        console.log("No such document!");
        return null;
      }
    } catch (error) {
      console.error("Error getting document: ", error);
    }
  },
  async getAllDocuments(collectionName) {
    try {
      const colRef = ref(db, collectionName);
      const snapshot = await get(colRef);
      if (snapshot.exists()) {
        const docs = Object.entries(snapshot.val()).map(([id, value]) => ({
          id,
          ...value,
        }));
        return docs;
      } else {
        console.log("No documents found!");
        return [];
      }
    } catch (error) {
      console.error("Error getting documents: ", error);
    }
  },
  listenForUpdates(collectionName, callback) {
    try {
      const colRef = ref(db, collectionName);
      const unsubscribe = onValue(colRef, (snapshot) => {
        callback(snapshot.val());
      });

      subscribeListeners.push(unsubscribe);
      return unsubscribe;
    } catch (error) {
      console.error("Error listening for updates: ", error);
    }
  },

  detachAllListeners() {
    subscribeListeners.forEach((unsubscribe) => {
      unsubscribe();
    });
    subscribeListeners.length = 0;
  },

  cancelOnDisconnectRefs() {
    onDisconnectRefs.forEach((onDisconnectRef) => {
      onDisconnectRef.cancel();
    });
    onDisconnectRefs.length = 0;
  },
};
export default realtimeService;
