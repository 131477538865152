<template>
  <a-modal
    title=""
    v-model:open="props.visible"
    :footer="null"
    :onCancel="onCancel"
    class="chat-modal"
  >
    <div class="content-chat top-space-chat">
      <!-- Content Header -->
      <div class="content-header">
        <h4>Messages</h4>
      </div>
      <!-- /Content Header -->
      <div class="row chat-window mt-3" ref="chatWindow">
        <div class="col-xl-12">
          <div class="chat-window" ref="chatWindow">
            <!-- Chat Left -->
            <div class="chat-cont-left">
              <div class="chat-header">
                <span>Chats</span>
              </div>

              <div class="chat-users-list">
                <div class="chat-scroll">
                  <a
                    v-if="newChat"
                    href="javascript:void(0);"
                    @click.prevent="openChat(newChat)"
                    :class="`notify-block d-flex open-chat ${
                      currentChat && currentChat.id === newChat.id
                        ? 'active'
                        : ''
                    }`"
                  >
                    <div class="media-img-wrap flex-shrink-0">
                      <div
                        :class="`avatar ${
                          newChat?.activeStatus === 'online'
                            ? 'avatar-online'
                            : newChat?.activeStatus === 'offline'
                            ? 'avatar-away'
                            : ''
                        }`"
                      >
                        <img
                          :src="
                            newChat.user.avatar?.url ||
                            `https://ui-avatars.com/api/?name=${newChat.user.fullName}`
                          "
                          alt="User Image"
                          class="avatar-img rounded-circle"
                        />
                      </div>
                    </div>
                    <div class="media-body chat-custom flex-grow-1">
                      <div>
                        <div class="user-name">{{ newChat.user.fullName }}</div>
                      </div>
                      <div>
                        <div class="last-chat-time block"> Bây giờ </div>
                      </div>
                    </div>
                  </a>

                  <div v-for="item in chats">
                    <a
                      href="javascript:void(0);"
                      @click.prevent="openChat(item)"
                      :class="`notify-block d-flex open-chat ${
                        currentChat && currentChat.id === item.id
                          ? 'active'
                          : ''
                      }`"
                    >
                      <div class="media-img-wrap flex-shrink-0">
                        <div
                          :class="`avatar ${
                            item.activeStatus === 'online'
                              ? 'avatar-online'
                              : item.activeStatus === 'offline'
                              ? 'avatar-away'
                              : ''
                          }`"
                        >
                          <img
                            :src="
                              item.user.avatar?.url ||
                              `https://ui-avatars.com/api/?name=${item.user.fullName}`
                            "
                            alt="User Image"
                            class="avatar-img rounded-circle"
                          />
                        </div>
                      </div>
                      <div class="media-body chat-custom flex-grow-1">
                        <div>
                          <div class="user-name">{{ item.user.fullName }}</div>
                          <div
                            class="user-last-chat"
                            :class="item.seen ? '' : 'chat-unseen'"
                            >{{ item.lastMessage }}</div
                          >
                        </div>
                        <div>
                          <div class="last-chat-time block">{{
                            getChatLastUpdated(item.lastUpdated)
                          }}</div>
                          <div
                            v-if="item.unread !== undefined"
                            class="badge badge-success rounded-pill"
                            >15</div
                          >
                        </div>
                      </div>
                    </a>
                  </div>
                  <div
                    class="text-center mt-2"
                    v-if="canLoadMoreChats"
                  >
                    <a
                      @click="onLoadMoreChat"
                      href="javascript:void(0)"
                      type="button"
                      >Load more</a
                    ></div
                  >
                </div>
              </div>
            </div>
            <!-- /Chat Left -->

            <!-- Chat Right -->
            <div class="chat-cont-right">
              <div class="chat-header" v-if="currentChat !== undefined">
                <a
                  id="back_user_list"
                  href="javascript:void(0)"
                  @click.prevent="goBack"
                  class="back-user-list"
                >
                  <i class="feather-chevron-left"></i>
                </a>
                <div class="notify-block d-flex">
                  <div class="media-img-wrap flex-shrink-0">
                    <div
                      :class="`avatar ${
                        currentChat?.activeStatus === 'online'
                          ? 'avatar-online'
                          : currentChat?.activeStatus === 'offline'
                          ? 'avatar-away'
                          : ''
                      }`"
                    >
                      <img
                        :src="
                          currentChat?.user?.avatar?.url ||
                          `https://ui-avatars.com/api/?name=${currentChat?.user?.fullName}`
                        "
                        alt="User Image"
                        class="avatar-img rounded-circle"
                      />
                    </div>
                  </div>
                  <div class="media-body flex-grow-1">
                    <div class="user-name">{{
                      currentChat?.user?.fullName
                    }}</div>
                    <div class="user-status" v-if="currentChat?.user?.isOnline"
                      >online</div
                    >
                  </div>
                </div>
              </div>
              <div class="chat-body">
                <div
                  class="chat-scroll"
                  id="chat-scroll"
                  @scroll="handleScroll"
                  ref="chatContainer"
                >
                  <div class="mt-5 text-center" v-if="currentChat === undefined"
                    >Vui lòng chọn để xem</div
                  >
                  <ul class="list-unstyled" v-for="(item, index) in messages">
                    <div
                      v-if="
                        !messages[index - 1] ||
                        !isSameDay(
                          messages[index - 1].createdAt,
                          item.createdAt
                        )
                      "
                      class="text-with-lines"
                    >
                      <p v-if="isToday(item.createdAt)">Hôm nay</p>
                      <p v-else-if="isYesterday(item.createdAt)">Hôm qua</p>
                      <p v-else>{{
                        formatDate(item.createdAt, "DD/MM/YYYY")
                      }}</p>
                    </div>
                    <li
                      class="notify-block sent d-flex"
                      v-if="item.owner === currentUser.id"
                    >
                      <div class="media-body flex-grow-1">
                        <div class="msg-box">
                          <div>
                            <p>{{ item.content }}</p>
                            <ul class="chat-msg-info">
                              <li>
                                <div class="chat-time">
                                  <span>{{
                                    formatDate(item.createdAt, "HH:mm")
                                  }}</span>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </li>

                    <li
                      class="notify-block received d-flex"
                      v-if="item.owner !== currentUser.id"
                    >
                      <div class="avatar flex-shrink-0">
                        <img
                          :src="
                            currentChat?.user?.avatar?.url ||
                            `https://ui-avatars.com/api/?name=${currentChat?.user?.fullName}`
                          "
                          alt="User Image"
                          class="avatar-img rounded-circle"
                        />
                      </div>
                      <div class="media-body flex-grow-1">
                        <div class="msg-box">
                          <div>
                            <p>{{ item.content }}</p>
                            <ul class="chat-msg-info">
                              <li>
                                <div class="chat-time">
                                  <span>{{
                                    formatDate(item.createdAt, "HH:mm")
                                  }}</span>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="chat-footer" v-if="currentChat !== undefined">
                <div class="input-group">
                  <input
                    type="text"
                    v-model="newMessage"
                    class="input-msg-send form-control rounded-pill"
                    placeholder="Type something"
                    @keyup.enter="onSendMessage"
                  />
                  <button
                    :disabled="!newMessage.trim()"
                    type="button"
                    @click="onSendMessage"
                    class="btn msg-send-btn rounded-pill ms-2"
                  >
                    <i class="fa-solid fa-paper-plane"></i>
                  </button>
                </div>
              </div>
              <button
                v-if="showNewMessageNoti"
                class="btn-default btn-new-messages"
                @click="scrollToBottom"
                >Có tin nhắn mới</button
              >
            </div>
            <!-- /Chat Right -->
          </div>
        </div>
      </div>
      <!-- /Row -->
    </div>
  </a-modal>
</template>
<script setup>
import { ref, onMounted, computed, watch, watchEffect, nextTick } from "vue";
import { useStore } from "vuex";
import { formatDate, isSameDay, isToday, isYesterday } from "@/utils/common";
import { message } from "ant-design-vue";
import { useInfiniteScroll } from "@vueuse/core";
import firestoreService from "@/services/FirestoreService";
import realtimeService from "@/services/RealtimeService";
import debounce from "lodash.debounce";
import { chatStatus } from "@/constants";

const props = defineProps({
  visible: { type: Boolean, required: true },
});

const emits = defineEmits(["close"]);

const store = useStore();
const chatContainer = ref(null);
const chatOpen = ref(false);
const chatWindow = ref(null);
const currentUser = computed(() => store.getters["auth/currentUser"]);
const chats = computed(() => store.getters["chat/chats"]);
const currentChat = computed(() => store.getters["chat/currentChat"]);
const messages = computed(() => store.getters["chat/currentMessages"]);

const canLoadMoreChats = computed(() => store.getters["chat/canLoadMoreChats"]);
const lastMessageOfCurrent = computed(
  () => store.getters["chat/lastMessageOfCurrent"]
);
const canLoadMoreMessages = computed(
  () => store.getters["chat/canLoadMoreMessages"]
);
const isLoadingMessages = computed(
  () => store.getters["chat/isLoadingMessages"]
);

const isNewChatStarted = ref(undefined);

const newChat = computed(() =>
  chats.value !== undefined &&
  currentChat.value !== undefined &&
  currentChat.value.isNewChat
    ? { ...currentChat.value }
    : null
);

const newMessage = ref("");
const isAtBottom = ref(true);
const showNewMessageNoti = ref(false);

let unsubscribeChatListener;
let unsubscribeMessageListener;
let unsubscribeUserStatusListeners;

const handleScroll = () => {
  const container = chatContainer.value;
  const scrollPosition = container.scrollTop + container.clientHeight;
  const scrollHeight = container.scrollHeight;
  isAtBottom.value = scrollHeight - scrollPosition <= 15;
};

const debounceSeen = debounce((chatId, messId) => {
  store.dispatch("chat/seen", { chatId, messId });
}, 2000);

watch(lastMessageOfCurrent, (newVal) => {
  if (isAtBottom.value || newVal.owner === currentUser.value.id) {
    scrollToBottom();
    store.commit("chat/seenChat", currentChat.value.id);
    debounceSeen(currentChat.value.id, newVal.id);
  }

  if (!isAtBottom.value && newVal.owner !== currentUser.value.id) {
    showNewMessageNoti.value = true;
  }
});

watch(
  () => props.visible,
  (newVal) => {
    if (newVal) {
      unsubscribeChatListener = firestoreService.listenToCollection(
        "chats",
        (docs) => {
          if (docs.length) {
            store.dispatch("chat/updateChats", docs);
            docs.map((item) => {
              if (
                unsubscribeUserStatusListeners.length &&
                !unsubscribeUserStatusListeners.find(
                  (listenter) => listenter.id === item.id
                )
              ) {
                unsubscribeUserStatusListeners.push({
                  id: item.id,
                  unsubscribe: realtimeService.listenForUpdates(
                    `connections/${
                      item.userFirst === currentUser.value.id
                        ? item.userSecond
                        : item.userFirst
                    }`,
                    (doc) => {
                      store.commit("chat/setChatActiveStatus", {
                        chatId: item.id,
                        status:
                          doc === null || doc === 0
                            ? chatStatus.offline
                            : doc > 0
                            ? chatStatus.online
                            : chatStatus.block,
                      });
                    }
                  ),
                });
              }
            });
          }
        },
        {
          ...(chats.value[0]
            ? {
                andConditions: [
                  {
                    field: "lastUpdated",
                    operator: ">",
                    value: new Date(chats.value[0].lastUpdated),
                  },
                ],
              }
            : {}),
          orConditions: [
            {
              field: "userFirst",
              operator: "==",
              value: currentUser.value.id,
            },
            {
              field: "userSecond",
              operator: "==",
              value: currentUser.value.id,
            },
          ],
          sortField: "lastUpdated",
          sortType: "desc",
        }
      );

      unsubscribeUserStatusListeners = chats.value.map((item) => {
        return {
          id: item.id,
          unsubscribe: realtimeService.listenForUpdates(
            `connections/${item.user.id}`,
            (doc) => {
              const chatId = `${Math.min(
                currentUser.value.id,
                item.user.id
              )}-${Math.max(currentUser.value.id, item.user.id)}`;

              store.commit("chat/setChatActiveStatus", {
                chatId,
                status:
                  doc === null || doc === 0
                    ? chatStatus.offline
                    : doc > 0
                    ? chatStatus.online
                    : chatStatus.block,
              });
            }
          ),
        };
      });
    } else {
      if (unsubscribeChatListener) unsubscribeChatListener();
      if (unsubscribeMessageListener) unsubscribeMessageListener();
      newChat.value = null;
      if (
        unsubscribeUserStatusListeners &&
        unsubscribeUserStatusListeners.length
      ) {
        unsubscribeUserStatusListeners.map((listenter) =>
          listenter.unsubscribe()
        );
      }
      store.commit("chat/setCurrentChat", undefined);
      store.commit("chat/setSuccess", undefined);
    }
  }
);
useInfiniteScroll(
  chatContainer,
  () => {
    if (
      isLoadingMessages.value === true ||
      canLoadMoreMessages.value === false ||
      !currentChat.value
    )
      return;

    store.dispatch("chat/getMessages", {
      chatId: currentChat.value.id,
      isFirstLoad: false,
    });
  },
  {
    distance: 10,
    direction: "top",
    interval: 500,
  }
);

watch(chats, (newList, oldList) => {
  if (newList !== undefined && newList.length) {
    if (
      currentChat.value &&
      oldList.findIndex((item) => item.id === currentChat.value.id) === -1
    ) {
      const newChatItem = newList.find(
        (item) => item.id === currentChat.value.id
      );

      if (newChatItem) {
        store.commit("chat/setCurrentChat", newChatItem);
        newChat.value = null;
      }
    }
  }
});

watch(currentChat, (newVal, oldVal) => {
  if (
    newVal !== undefined &&
    (oldVal === undefined ||
      newVal.id !== oldVal.id ||
      newVal.isNewChat !== oldVal.isNewChat)
  ) {
    isNewChatStarted.value = true;

    if (unsubscribeMessageListener) {
      unsubscribeMessageListener();
    }
    unsubscribeMessageListener = firestoreService.listenToCollection(
      `chats/${newVal.id}/messages`,
      (docs) => {
        if (docs.length) {
          store.dispatch("chat/addUpcomingMessages", docs);
        }
      },
      {
        andConditions: [
          {
            field: "owner",
            operator: "==",
            value: newVal.user.id,
          },
          {
            ...(newVal.lastUpdated
              ? {
                  field: "createdAt",
                  operator: ">",
                  value: new Date(newVal.lastUpdated),
                }
              : {}),
          },
        ],
        sortField: "createdAt",
        sortType: "desc",
      }
    );

    store.commit("chat/setCanLoadMoreMessages", true);
    store.dispatch("chat/getMessages", {
      chatId: newVal.id,
      isFirstLoad: true,
    });
  }
});

watch(messages, (newVal) => {
  if (
    newVal !== undefined &&
    newVal.length &&
    isNewChatStarted.value === true
  ) {
    if (!currentChat.value.seen) {
      store.commit("chat/seenChat", currentChat.value.id);
      store.dispatch("chat/seen", {
        chatId: currentChat.value.id,
        messId: null,
      });
    }

    scrollToBottom();
    isNewChatStarted.value = false;
  }
});

const onSendMessage = () => {
  if (newMessage.value.trim()) {
    const data = {
      chatId: currentChat.value.id,
      data: {
        content: newMessage.value,
        type: "text",
        owner: currentUser.value.id,
      },
    };
    if (newChat.value && newChat.value.id === currentChat.value.id)
      store.dispatch("chat/createChat", data);
    else store.dispatch("chat/sendMessage", data);
    newMessage.value = "";
  }
};

const onLoadMoreChat = () => {
  store.dispatch("chat/getList", { isFirstLoad: false });
};

const getChatLastUpdated = (lastUpdated) => {
  if (isToday(lastUpdated)) {
    return formatDate(lastUpdated, "HH:mm");
  } else if (isYesterday(lastUpdated)) {
    return "Hôm qua";
  } else return formatDate(lastUpdated, "DD/MM/YYYY");
};

const onCancel = () => {
  chatWindow.value?.classList?.remove("chat-slide");
  chatOpen.value = false;
  emits("close");
};

const initializeChatApp = () => {
  document.addEventListener("visibilitychange", () => {
    if (document.hidden) {
      onCancel();
    }
  });

  if (window.innerWidth > 991) {
    chatWindow?.value?.classList.remove("chat-slide");
  }

  document.addEventListener("click", (event) => {
    const target = event.target;

    if (
      hasClass(target, "open-chat") &&
      hasClass(target.parentNode, "chat-users-list")
    ) {
      if (window.innerWidth <= 991) {
        chatWindow?.value?.classList.add("chat-slide");
        chatOpen.value = true;
      }
      event.preventDefault();
    }

    if (target.id === "back_user_list" && window.innerWidth <= 991) {
      chatWindow.value?.classList?.remove("chat-slide");
      chatOpen.value = false;
      event.preventDefault();
    }
  });
};

const openChat = (item) => {
  store.commit("chat/setCurrentChat", item);
  chatWindow.value?.classList?.add("chat-slide");
  chatOpen.value = true;
};

const goBack = () => {
  chatWindow.value?.classList?.remove("chat-slide");
  chatOpen.value = false;
};

const hasClass = (element, className) => {
  return (" " + element.className + " ").indexOf(" " + className + " ") > -1;
};

const scrollToBottom = () => {
  nextTick(() => {
    if (chatContainer.value) {
      chatContainer.value.scrollTo({
        top: chatContainer.value.scrollHeight,
        ...(showNewMessageNoti.value ? { behavior: "smooth" } : {}),
      });
      isAtBottom.value = true;
    }

    if (showNewMessageNoti.value === true) {
      store.commit("chat/seenChat", currentChat.value.id);
      store.dispatch("chat/seen", {
        chatId: currentChat.value.id,
        messId: null,
      });
      showNewMessageNoti.value = false;
    }
  });
};

onMounted(async () => {
  initializeChatApp();
});
</script>
